import { graphql } from 'gatsby';
import React from 'react';
import SingleTemplate from './single';
import { getBlogPostMeta } from '../utils/helpers';
import { PAGE_TYPE, PAGE_PREFIX } from '../utils/constants';
import BlogProxy from '../components/proxy/blog-blocks';

const LifeBlogPostTemplate = props => {
  const { site, post, markdownRemark } = props.data;

  const seo = getBlogPostMeta({
    pathname: props.location.pathname,
    siteMetadata: site.siteMetadata,
    frontmatter: markdownRemark.frontmatter,
    post,
  });

  return (
    <SingleTemplate
      seo={seo}
      pageType={PAGE_TYPE.LifeBlogPost}
      pagePrefix={PAGE_PREFIX.LifeBlog}
      proxy={BlogProxy}
      {...props}
    />
  );
};

export default LifeBlogPostTemplate;

export const lifeBlogPostPageQuery = graphql`
  query LifeBlogPostPageQuery($slug: String!) {
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
    tags: allBlogTag(filter: { visibility: { eq: "public" } }) {
      edges {
        node {
          name
          slug
          visibility
        }
      }
    }
    post: lifeBlogPost(slug: { eq: $slug }) {
      id
      primary_author {
        name
        profile_image
      }
      authors {
        name
        profile_image
      }
      tags {
        name
        slug
        visibility
      }
      primary_tag {
        name
      }
      title
      slug
      feature_image
      featured
      visibility
      url
      excerpt
      html
      published_at
      updated_at
    }
    markdownRemark(fields: { slug: { eq: "/life-at-bunny-post/" } }) {
      ...PageMarkdownFragment
    }
    ...ConfigFragment
  }
`;
